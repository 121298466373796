<template>

<div class="sol-content-center-w5">
  <div class="w3-container w3-center">
    <h3>Oppdater kategori</h3>
  </div>
  <div class="w3-container">
    <label>Kategori:</label>
    <div class="w3-row w3-margin-bottom">
      <div class="w3-col sol-w90-pct">
        <select @change="loadWords" v-model="selectedCategory" class="w3-select w3-border" name="cat-select">
          <option v-for="option in categorySelectOptions" :key="option.value" :value="option.value">
            {{ option.text }}
          </option>
        </select>        
      </div>
      <div v-if="categorySelectOptions.length > 0 && emptyCategory" class="w3-col sol-w10-pct">
        <i @click="deleteCategory" class="fa fa-trash sol-icon-link"></i>
      </div>
    </div>
    <div v-if="!emptyCategory" class="sol-paginated-table">
      <paginate name="words" :list="words" :per="perPage" class="w3-margin-bottom">
        <div v-for="word in paginated('words')" :key="word.value" class="w3-row">
          <div class="w3-col w3-padding w3-white w3-border w3-border-grey w3-round sol-w90-pct">
           {{ word.text }}
          </div>
          <div class="w3-col sol-w10-pct">
            <i @click="deleteWord(word)" class="fa fa-trash sol-icon-link"></i>
          </div>
        </div>
      </paginate>
      <div class="w3-center w3-margin-bottom">
        <paginate-links v-if="words.length > perPage" for="words" :simple="{next: '»', prev: '«'}">
        </paginate-links>
      </div>
    </div>
    <div class="w3-center">
      <button @click="newWord" v-if="categorySelectOptions.length > 0" class="sol-form-button w3-btn w3-round">
        Nytt ord ...
      </button>
    </div>    
  </div>
  <div v-if="showModal" class="w3-modal">
    <div class="sol-w4 w3-modal-content w3-card w3-center w3-border">
      <header class="w3-container sol-light-blue sol-text-pale-blue"> 
        <span @click="cancelDelete" class="w3-btn w3-large w3-display-topright">&times;</span>
        <h4>Bekreft sletting</h4>
      </header>
      <div class="w3-panel">
        <div class="fa fa-warning w3-xlarge w3-text-amber"></div>
        <div>{{ modalText }}</div>
      </div>
      <div class="w3-panel">
        <button @click="executeDelete" class="sol-submit-button w3-btn w3-round">OK</button>&nbsp;
        <button @click="cancelDelete" class="sol-submit-button w3-btn w3-round">Avbryt</button>
      </div>     
    </div>
  </div>
</div>

</template>

<script>

import { db, storage } from '../firebase';
export default {

  data() {
    return {
      selectedCategory: '',
      categorySelectOptions: [],
      words: [],
      paginate: ['words'],
      perPage: 8,
      deleteItem: {},
      emptyCategory: true,
      showModal: false,
      modalText: ''
    }
  },

  beforeRouteEnter(to, from, next) {
    next(vm => {
      if (from.fullPath.startsWith('/nytt-ord/')) {
        vm.selectedCategory = from.fullPath.replace('/nytt-ord/', '');
      }
    });
  },

  created() {
    this.loadCategories();
  },

  methods: {

    loadCategories() {
      this.categorySelectOptions = [];
      db.ref('categories').once('value')
        .then(snapshot => {
          Object.keys(snapshot.val()).forEach(catKey => {
            const catVal = snapshot.val()[catKey];
            if (Object.keys(catVal).includes('subcategories')) {
              Object.keys(catVal.subcategories).forEach(subCatKey => {
                const subCatVal = catVal.subcategories[subCatKey];
                this.categorySelectOptions.push({ 
                  text: catVal.name + ' > ' + subCatVal.name, 
                  value: catKey + '/' + subCatKey
                });
              })
            }
            else {
              this.categorySelectOptions.push({ 
                text: catVal.name, 
                value: catKey
              });            
            }
          });
      })
      .then(() => {
        if (this.categorySelectOptions.length > 0) {
          if (this.selectedCategory === '') {
            this.selectedCategory = this.categorySelectOptions[0].value;
          }
          this.loadWords();
        }
      })
    },

    loadWords() {
      db.ref('words/' + this.selectedCategory).once('value')
        .then(snapshot => {
          if (snapshot.exists()) {
            let loadedWords = [];
            Object.keys(snapshot.val()).forEach(wordKey => {
              const wordText = snapshot.val()[wordKey].text;
              loadedWords.push({
                text: wordText,
                value: wordKey
              })
            })
            this.words = loadedWords;
            this.emptyCategory = false;
          }
          else {
            this.words = [{text: '', value: ''}];
            this.emptyCategory = true;
          }
        })
    },

    deleteCategory() {
      this.deleteItem = this.categorySelectOptions.find(cat => cat.value === this.selectedCategory);
      this.modalText = 'Kategori: ' + this.deleteItem.text;
      this.showModal = true;
    },
 
    deleteWord(selectedWord) {
      this.deleteItem = selectedWord;
      this.modalText = 'Ord: ' + this.deleteItem.text;
      this.showModal = true;
    },

    executeDelete() {
      this.showModal = false;
      let categoryKeys = this.selectedCategory.split('/');
      let dbRefPathWord = 'words/' + this.selectedCategory;
      let dbRefPathCat = 'categories/' + categoryKeys[0];
      if (categoryKeys.length > 1) {
        dbRefPathCat += '/subcategories/' + categoryKeys[1]
      }
      if (this.emptyCategory) {  // Delete category
        db.ref(dbRefPathCat).once('value')
          .then(snapshot => {
            storage.refFromURL(snapshot.val().iconUrl).delete();
          })
          .then(() => {
            db.ref(dbRefPathCat).remove();
          })
          .then(() => {
            this.deleteItem = {};
            this.selectedCategory = '';
            this.loadCategories();
          })
      }
      else {  // Delete word
        let i = this.words.findIndex(word => word.value === this.deleteItem.value);
        let wordKey = this.words[i].value;
        let word = {};
        db.ref(dbRefPathWord).child(wordKey).once('value')
          .then(snapshot => {
            word = snapshot.val();
            storage.refFromURL(word.imageUrl).delete();
          })
          .then(() => {
            storage.refFromURL(word.audioUrl).delete();
          })
          .then(() => {
            db.ref(dbRefPathWord).child(wordKey).remove();
          })
          .then(() => {
            this.deleteItem = {};
            if (this.words.length > 1) {
              this.words.splice(i, 1);
            }
            else {
              // Workaround. If the word array is emptied the paginate table displays nothing 
              // after loadWords. May be a bug in the vue-paginate component.
              this.words = [{text: '', value: ''}];
              this.emptyCategory = true;
              db.ref(dbRefPathCat).child('hasWords').set(false);
            }
          })
      }
    },    

    cancelDelete() {
      this.showModal = false;
      this.deleteItem = {};
    },

    newWord() {
      this.$router.push({
        path: '/nytt-ord/' + this.selectedCategory
      })
    }

  }

}

</script>

<style scoped>

.w3-modal {
  display: block;
  padding-top: 100px;
}

.sol-paginated-table ul {
  padding: 0;
  margin: 0;
}

.sol-paginated-table .paginate-links {
  cursor: pointer;
  list-style-type: none;
}

/* Using deep selector >>> because .paginate-links li and a is not scoped */
.sol-paginated-table .paginate-links >>> li {
  display: inline-block;
}

.sol-paginated-table .paginate-links >>> a {
  font-size: 20px;
  padding: 3px 12px;
  border: 1px solid;
  border-color: #888;
  border-radius: 4px;
}

.sol-paginated-table .paginate-links >>> a:hover{
  box-shadow: 0 8px 16px 0 #00000033, 0 6px 20px 0 #00000030;
}

.sol-icon-link {
  cursor: pointer;
  font-size: 36px;
  margin-left: 16px;
}

.sol-w10-pct {
  width: 10%
}

.sol-w90-pct {
  width: 90%
}

</style>
