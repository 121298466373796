import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/storage';

const firebaseApp = firebase.initializeApp({
    apiKey: "AIzaSyBEoE4TqHFTdLvyvWK2s11X7yLFexEgQ9s",
    authDomain: "seogles-4633f.firebaseapp.com",
    databaseURL: "https://seogles-4633f-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "seogles-4633f",
    storageBucket: "seogles-4633f.appspot.com",
    messagingSenderId: "881090578902",
    appId: "1:881090578902:web:38759d54d12b72a82abc77"
});

const auth = firebaseApp.auth();
const db = firebaseApp.database();
const storage = firebaseApp.storage();

export { auth, db, storage };
