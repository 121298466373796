import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex)

export default new Vuex.Store({

  state: {
    adminLoggedIn: false,
    showAdminMenuDropdown: false,
    showNavigateBackArrow: false,
    playAudio: false,
    showImage: true,
    writeText: false    
  },

  mutations: {
  },

  actions: {
  },

  modules: {
  }

})
