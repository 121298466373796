export function trimAll(str) {
  str = str.trim();
  str = str.replace(/\s+/g, ' ');
  return str;
}

export function trimKey(key) {
  key = trimAll(key);
  key = key.toLowerCase();
  key = key.replace(/[.:,;'*+=?/!"#¤%&(){}]/g,'');
  const mapping = {'æ':'ae','ø':'oe','å':'aa',' ':'-'};
  key = key.replace(/[æøå ]/g, m => mapping[m]);
  return key;
}
