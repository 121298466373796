<template>

<div class="sol-content-center-w4">
  <div class="w3-container w3-center">
    <h3>Logg inn</h3>
  </div>
  <form @submit="onSubmit" class="w3-container">
    <p>
      <label>Epostadresse:</label>
      <input type="text" v-model="form.email" class="w3-input w3-border" name="email">
    </p>
    <p>
      <label>Passord:</label>
      <input type="password" v-model="form.password" class="w3-input w3-border" name="pwd">
    </p>
    <div class="w3-center w3-padding">
      <button class="sol-submit-button w3-btn w3-round">Logg inn</button>
      <div class="sol-error-msg">{{ errorMsg }}</div>
    </div>
  </form>
</div>

</template>

<script>

import { auth } from '../firebase';
export default {

  data() {
    return {
      form: {
        email: '',
        password: ''
      },
      errorMsg: ''
    }
  },

  created() {
    if (this.$store.state.adminLoggedIn) {
      this.$router.go(-1);
    }
  },

  methods: {

    onSubmit(event) {
      event.preventDefault();
      let email = this.form.email.toLowerCase();
      let password = this.form.password;
      if ((email === '') || (password === '')) {
        this.errorMsg = 'Alle felter må fylles inn';
        return;
      }
      auth.signInWithEmailAndPassword(email, password)
        .then(() => {
          this.$store.state.adminLoggedIn = true;
          this.form.email = '';
          this.form.password = '';
          this.errorMsg = '';
          this.$router.push('/');
        })
        .catch(() => {
          this.errorMsg = 'Ugyldig epostadresse eller passord';
        });
    }

  }

}

</script>

<style scoped>

</style>
