<template>

<div class="sol-content-center-w5">
  <div class="w3-container w3-center">
    <h3>Legg til ny kategori</h3>
  </div>
  <form @submit="onSubmit" class="w3-container">
    <p>
      <input type="checkbox" v-model="subCategoryChecked" class="sol-check" name="subcat-check">
      <label class="w3-padding-small">Underkategori til:</label>
      <select v-model="selectedCategory" :disabled="!subCategoryChecked" class="w3-select w3-border" name="cat-select">
        <option v-for="option in categorySelectOptions" :key="option.value" :value="option.value">
          {{ option.text }}
        </option>
      </select>
    </p>
    <p>
      <label>Navn:</label>
      <input type="text" v-model="form.categoryName" class="w3-input w3-border" autocomplete="off" name="cat-name">
    </p>
    <div>
      <label for="browse-icon-id" class="sol-form-button w3-btn w3-round">Bilde ...</label>
      <input type="file" @change="browseIcon" accept="image/*" id="browse-icon-id" class="sol-hidden-element">
      <span>{{ form.iconFile.name }}</span>
    </div>
    <div class="w3-center">
      <button :disabled="showSubmitOk" class="sol-submit-button w3-btn w3-round">OK</button>
      <div class="sol-status-msg" :class="{ 'sol-error-msg': showErrorMsg, 'sol-success-msg': showSubmitOk }">
        {{ statusMsg }}
      </div>
    </div>
  </form>
</div>

</template>

<script>

import { db, storage } from '../firebase';
import * as utils from '../utils';
export default {

  data() {
    return {
      categories: {},
      subCategoryChecked: false,
      selectedCategory: '',
      categorySelectOptions: [],
      form: {
        categoryName: '',
        iconFile: ''
      },
      showErrorMsg: false,
      showSubmitOk: false,
      statusMsg: ''
    }
  },

  created() {
    this.initCategories();
  },

  methods: {

    initCategories() {
      db.ref('categories').once('value')
        .then(snapshot => {
          this.categories = snapshot.val();
        })
        .then(() => {
          this.selectedCategory = '';
          this.categorySelectOptions = [{ 
            text: 'Velg hovedkategori', 
            value: '' 
          }];
          Object.keys(this.categories).forEach(key => {
            const val = this.categories[key];
            if (!val.hasWords) {
              this.categorySelectOptions.push({ 
                text: val.name, 
                value: key
              });
            }
          });
        })
    },

    browseIcon(event) {
      event.preventDefault();
      this.form.iconFile = event.target.files[0];
    },

    onSubmit(event) {
      event.preventDefault();
      const categoryName = utils.trimAll(this.form.categoryName);
      const categoryKey = utils.trimKey(categoryName);
      const iconFile = this.form.iconFile;

      this.statusMsg = '';
      if (this.subCategoryChecked && (this.selectedCategory === '')) {
        this.statusMsg = 'Velg en hovedkategori';
      }
      else if (categoryName === '') {
        this.statusMsg = 'Navn må angis';
      }
      else if ((this.subCategoryChecked &&
                Object.keys(this.categories[this.selectedCategory]).includes('subcategories') &&
                Object.keys(this.categories[this.selectedCategory].subcategories).includes(categoryKey)) ||
               (!this.subCategoryChecked &&
                Object.keys(this.categories).includes(categoryKey))) {
        this.statusMsg = 'Kategorien ' + categoryName + ' er registrert fra før';
      }
      else if (iconFile === '') {
        this.statusMsg = 'Bilde må angis';
      }
      if (this.statusMsg !== '') {
        this.showErrorMsg = true;
        return;
      }

      let storageRefPath = categoryKey + '/icon/' + iconFile.name;
      let dbRefPath = 'categories/' + categoryKey;
      if (this.subCategoryChecked) {
        storageRefPath = this.selectedCategory + '/' + storageRefPath;
        dbRefPath = 'categories/' + this.selectedCategory + '/subcategories/' + categoryKey;
      }
      storage.ref(storageRefPath).put(iconFile)
        .then(snapshot => {
          return storage.ref(snapshot.metadata.fullPath).toString();
        })
        .then(storagePath => {
          db.ref(dbRefPath).set({
            name: categoryName,
            iconUrl: storagePath,
            hasWords: false
          });
        })
        .then(() => {
          this.initCategories();
          this.showErrorMsg = false;
          this.showSubmitOk = true;
          this.statusMsg = 'Ny kategori ' + categoryName + ' er registrert';
          this.afterSubmit();
        })
        .catch(() => {
          this.showErrorMsg = true;
          this.statusMsg = 'Registrering av ny kategori ' + categoryName + ' feilet';
        })
    },

    afterSubmit() {
      setTimeout(() => {
        this.form.categoryName = '';
        this.form.iconFile = '';
        this.showSubmitOk = false;
        this.statusMsg = '';
      }, 2500);
    }

  }

}

</script>

<style scoped>

.sol-check {
  width: 18px;
  height: 18px;
  position: relative;
  top: 3px;
}

.sol-form-button {
  margin: 8px 10px 14px 0;
}

</style>
