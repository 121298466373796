<template>

<div class="sol-content-center-w8">
  <div class="w3-container w3-center">
    <h3>
      {{ mainCategoryName }}
      <img :src="mainCategoryIconUrl" class="sol-thumbnail">
    </h3>
  </div>
  <div class="sol-categories w3-row">
    <div class="w3-col" :class="{ 'l2': numSubCat === 2, 'm3 l4': numSubCat === 1 }"></div>
    <div v-for="subCat in subCategories" :key="subCat.link" class="w3-col m6 l4">
      <router-link :to="subCat.link" class="sol-category w3-btn w3-round w3-block w3-large">
        <span class="w3-left">{{ subCat.name }}</span>
        <img :src="subCat.iconUrl" class="sol-thumbnail w3-right">
      </router-link>
    </div>
  </div>
</div>

</template>

<script>

import { db, storage } from '../firebase';
export default {
  
  data() {
    return {
      mainCategoryName: '',
      mainCategoryIconUrl: '',
      subCategories: []
    }
  },

  computed: {
    numSubCat() {
      return this.subCategories.length;
    }
  },

  created() {
    let catKey = this.$route.params.cat;
    db.ref('categories').child(catKey).once('value')
      .then(snapshot => {
        const catVal = snapshot.val();
        this.mainCategoryName = catVal.name;
        this.mainCategoryIconUrl = catVal.iconUrl;
        Object.keys(catVal.subcategories).forEach(subCatKey => {
          const subCatVal = catVal.subcategories[subCatKey];
          if (subCatVal['hasWords']) {
            this.subCategories.push({
              link: '/ord/' + catKey + '/' + subCatKey,
              name: subCatVal['name'],
              iconUrl: subCatVal['iconUrl']
            });
          }
        })
      })
      .then(() => {
        return storage.refFromURL(this.mainCategoryIconUrl).getDownloadURL();
      })
      .then(iconUrl => {
        this.mainCategoryIconUrl = iconUrl;
        this.subCategories.forEach((subCategory, index) => {
          storage.refFromURL(subCategory.iconUrl).getDownloadURL()
            .then(iconUrl => {
              this.subCategories[index].iconUrl = iconUrl;    
            })
        })
      })
  }

}

</script>

<style scoped>

.sol-categories {
  margin-top: 0;
}

</style>