<template>

<span>
  <span class="sol-rotate-screen"><RotateScreen/></span>
  <span class="sol-normal-screen">
    <div class="sol-flexbox">
      <div class="row navbar"><NavBar/></div>
      <div class="row content"><router-view/></div>
      <div class="row footer"><Footer/></div>
    </div>
  </span>
</span>

</template>

<script>

import RotateScreen from '@/components/RotateScreen.vue';
import NavBar from '@/components/NavBar.vue';
import Footer from '@/components/Footer.vue';
export default {

  components: {
    RotateScreen,
    NavBar,
    Footer
  }

}

</script>

<style>

@import url('https://www.w3schools.com/w3css/4/w3.css');
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css');
@import 'assets/sol-style.css';

.sol-rotate-screen {
  display: none;
}

/* @media screen and (max-height: 600px) and (orientation: landscape) {

  .sol-rotate-screen {
    display: block;
  }

  .sol-normal-screen {
    display: none;
  }

} */

.sol-flexbox {
  display: flex;
  flex-flow: column;
  height: 100%;
}

.sol-flexbox .row.navbar {
  flex: 0 1 auto;
}

.sol-flexbox .row.content {
  flex: 1 1 auto;
  background-color: #eef1f6;
}

.sol-flexbox .row.footer {
  flex: 0 1 auto;
}

</style>
