<template>

<div class="sol-content-center-w8">
  <div class="sol-categories w3-row">
    <div class="w3-col" :class="{ 'l2': numCat === 2, 'm3 l4': numCat === 1 }"></div>
    <div v-for="cat in categories" :key="cat.link" class="w3-col m6 l4">
      <router-link :to="cat.link" class="sol-category w3-btn w3-round w3-block w3-large">
        <span class="w3-left">{{ cat.name }}</span>
        <img :src="cat.iconUrl" class="sol-thumbnail w3-right">
      </router-link>
    </div>
  </div>
</div>

</template>

<script>

import { db, storage } from '../firebase';
export default {

  data() {
    return {
      categories: []
    }
  },

  computed: {
    numCat() {
      return this.categories.length;
    }
  },

  created() {
    db.ref('categories').once('value')
      .then(snapshot => {
        Object.keys(snapshot.val()).forEach(catKey => {
          const catVal = snapshot.val()[catKey];
          if (Object.keys(catVal).includes('subcategories')) {
            Object.keys(catVal.subcategories).some(subCatKey => {
              const subCatVal = catVal.subcategories[subCatKey];
              if (subCatVal['hasWords']) {
                this.categories.push({
                  link: '/kategori/' + catKey,
                  name: '+ ' + catVal['name'],
                  iconUrl: catVal['iconUrl']
                });
                return true;
              }
            })
          }
          else if (catVal['hasWords']) {
            this.categories.push({
              link: '/ord/' + catKey,
              name: catVal['name'],
              iconUrl: catVal['iconUrl']
            });
          }
        })
      })
      .then(() => {
        this.categories.forEach((category, index) => {
          storage.refFromURL(category.iconUrl).getDownloadURL()
            .then(iconUrl => {
              this.categories[index].iconUrl = iconUrl;    
            })
        })
      })
  }

}

</script>
