<template>

<div class="sol-light-blue w3-border">
  <div class="sol-navbar-content">
    <div class="w3-display-container">
      <div @click="navigateBack" v-if="$store.state.showNavigateBackArrow" class="sol-pointer w3-display-left">
        <img src="../assets/back-arrow.png">
      </div>
      <router-link to="/" class="w3-display-middle">
        <img src="../assets/logo.png">
      </router-link>
      <div v-if="$store.state.adminLoggedIn" class="w3-display-right">
        <div id="sol-admin-menu" class="sol-text-pale-blue w3-btn w3-hover-none w3-xlarge w3-padding-24">
          Admin <img src="../assets/down-arrow.png">
        </div>
      </div>
    </div>
    <div v-if="$store.state.showAdminMenuDropdown">
      <div class="w3-dropdown-content w3-bar-block w3-border">
        <router-link to="/ny-kategori" class="w3-btn w3-bar-item">
          Ny kategori
        </router-link>
        <router-link to="/oppdater-kategori" class="w3-btn w3-bar-item">
          Oppdater kategori
        </router-link>
        <div @click="showVersion = true" class="w3-btn w3-bar-item">Vis versjon</div>
        <div @click="signOut" class="w3-btn w3-bar-item">Logg ut</div>
      </div>
    </div>
  </div>
  <div v-if="showVersion" class="w3-modal">
    <div class="sol-w4 w3-modal-content w3-card w3-center w3-border">
      <header class="w3-container sol-light-blue sol-text-pale-blue"> 
        <span @click="showVersion = false" class="w3-btn w3-large w3-display-topright">&times;</span>
        <h4>Vis versjon</h4>
      </header>
      <div class="w3-panel">
        <div>Versjon 1.00</div>
        <div>Utgitt 21. april 2021</div>
      </div>
      <div class="w3-panel">
        <button @click="showVersion = false" class="sol-submit-button w3-btn w3-round">OK</button>
      </div>     
    </div>
  </div>

</div>

</template>

<script>

import { auth } from '../firebase';
export default {

  data() {
    return {
      showVersion: false
    }
  },

  mounted() {
    document.addEventListener('click', this.onClick);
  },

  beforeDestroy() {
    document.removeEventListener('click', this.onClick);
  },

  methods: {

    navigateBack() {
      this.$router.go(-1);
    },

    onClick(event) {
      if (event.target.closest('#sol-admin-menu')) {
        this.$store.state.showAdminMenuDropdown = !this.$store.state.showAdminMenuDropdown;
      }
      else {
        this.$store.state.showAdminMenuDropdown = false;
      }
    },

    signOut() {
      auth.signOut()
        .catch(() => {
        })
        .then(() => {
          this.$store.state.adminLoggedIn = false;
          let path = '/' + this.$route.path.split('/')[1];
          path = path.toLowerCase();
          if ((path === '/ny-kategori') || (path === '/oppdater-kategori') || (path === '/ny-kategori')) {
            this.$router.push('/');
          }
        })
    }

  }

}

</script>

<style scoped>

.sol-navbar-content {
  position: relative;
  max-width: 800px;
  margin: 0 auto;
}

.sol-navbar-content > .w3-display-container {
  height: 84px;
}

.w3-dropdown-content {
  display: block;
  width: 180px;
  right: 0;
}

.w3-bar-block .w3-bar-item {
  padding-top: 5px;
  padding-bottom: 5px;
}

.w3-modal {
  display: block;
  padding-top: 100px;
}

</style>
