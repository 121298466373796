<template>

<div class="sol-content-center-w5">
  <div class="w3-container w3-center">
    <h3>Legg til nytt ord</h3>
  </div>
  <form @submit="onSubmit" class="w3-container">
    <p>Kategori: {{ categoryName }}</p>
    <p>
      <label>Tekst:</label>
      <input type="text" v-model="form.wordText" class="w3-input w3-border" autocomplete="off" name="word-text">
    </p>
    <div>
      <label for="browse-image-id" class="sol-form-button w3-btn w3-round">Bilde ...</label>
      <input type="file" @change="browseImage" accept="image/*" id="browse-image-id" class="sol-hidden-element">
      <span>{{ form.imageFile.name }}</span>
    </div>
    <div>
      <label for="browse-audio-id" class="sol-form-button w3-btn w3-round">Lyd ...</label>
      <input type="file" @change="browseAudio" accept="audio/*" id="browse-audio-id" class="sol-hidden-element">
      <span>{{ form.audioFile.name }}</span>
    </div>
    <div class="w3-center">
      <button :disabled="showSubmitOk" class="sol-submit-button w3-btn w3-round">OK</button>
      <div class="sol-status-msg" :class="{ 'sol-error-msg': showErrorMsg, 'sol-success-msg': showSubmitOk }">
        {{ statusMsg }}
      </div>
    </div>
  </form>
</div>

</template>

<script>

import { db, storage } from '../firebase';
export default {

  data() {
    return {
      categoryKey: '',
      subCategoryKey: '',
      categoryName: '',
      form: {
        wordText: '',
        imageFile: '',
        audioFile: ''
      },
      showErrorMsg: false,
      showSubmitOk: false,
      statusMsg: ''
    }
  },

  created() {
    this.categoryKey = this.$route.params.cat;
    if ('subcat' in this.$route.params) {
      this.subCategoryKey = this.$route.params.subcat;
    }
    let dbRefPath = 'categories/' + this.categoryKey;
    if (this.subCategoryKey !== '') {
      dbRefPath += '/subcategories/' + this.subCategoryKey;
    }
    db.ref(dbRefPath).child('name').once('value')
      .then(snapshot => {
        this.categoryName = snapshot.val();
      })
  },

  methods: {

    browseImage(event) {
      event.preventDefault();
      this.form.imageFile = event.target.files[0];
    },

    browseAudio(event) {
      event.preventDefault();
      this.form.audioFile = event.target.files[0];
    },

    onSubmit(event) {
      event.preventDefault();
      const wordText = this.form.wordText;
      const imageFile = this.form.imageFile;
      const audioFile = this.form.audioFile;

      this.statusMsg = '';
      if (wordText === '') {
        this.statusMsg = 'Tekstfeltet må fylles ut';
      }
      else if ((imageFile === '') || (audioFile === '')) {
        this.statusMsg = 'Bilde og lyd må angis';
      }
      if (this.statusMsg !== '') {
        this.showErrorMsg = true;
        return;
      }

      let imageUrl = '';
      let audioeUrl = '';
      let storageRefPath = this.categoryKey + '/';
      let dbRefPathCat = 'categories/' + this.categoryKey;
      let dbRefPathWord = 'words/' + this.categoryKey;
      if (this.subCategoryKey !== '') {
        storageRefPath += this.subCategoryKey + '/';
        dbRefPathCat += '/subcategories/' + this.subCategoryKey;
        dbRefPathWord += '/' + this.subCategoryKey;
      }
      db.ref(dbRefPathWord).once('value')
        .then(snapshot => {
          if (snapshot.exists()) {
            let wordExists = false;
            Object.values(snapshot.val()).some(word => {
              if (word.text.toLowerCase() === wordText.toLowerCase()) {
                wordExists = true;
                return true;
              }
            })
            return new Promise((resolve, reject) => {
              wordExists ? reject('wordExists') : resolve();
            });
          }
        })
        .then(() => {
          return storage.ref(storageRefPath + imageFile.name).put(imageFile);
        })
        .then(snapshot => {
          imageUrl = storage.ref(snapshot.metadata.fullPath).toString();
        })
        .then(() => {
          return storage.ref(storageRefPath + audioFile.name).put(audioFile);
        })
        .then(snapshot => {
          audioeUrl = storage.ref(snapshot.metadata.fullPath).toString();
        })
        .then(() => {
          db.ref(dbRefPathWord).push({
            text: wordText,
            imageUrl: imageUrl,
            audioUrl: audioeUrl
          });         
        })
        .then(() => {
          db.ref(dbRefPathCat).child('hasWords').set(true);
        })
        .then(() => {
          this.showErrorMsg = false;
          this.showSubmitOk = true;
          this.statusMsg = 'Nytt ord ' + wordText + ' er registrert';
          this.afterSubmit();
        })
        .catch(error => {
          if (error === 'wordExists') {  
            this.statusMsg = 'Ordet ' + wordText + ' er registrert fra før';
          }
          else {
            this.statusMsg = 'Registrering av nytt ord ' + wordText + ' feilet';
          }
          this.showErrorMsg = true;
        })
    },

    afterSubmit() {
      setTimeout(() => {
        this.form.wordText = '';
        this.form.imageFile = '';
        this.form.audioFile = '';
        this.showSubmitOk = false;
        this.statusMsg = '';
      }, 2500);
    }

  }

}

</script>

<style scoped>

.sol-form-button {
  width: 90px;
  margin: 8px 10px 14px 0;
}

</style>
