<template>

<div class="sol-words-flexbox sol-content-center-wx">
  <div class="tools-flexrow sol-border-side-bottom w3-white">
    <div class="sol-content-center-w4">
      <div class="w3-row">
        <div class="w3-col s4 w3-center">
          <div @click="toggleAudioMode" v-if="$store.state.playAudio">
            <img src="../assets/ear.png" class="sol-tool-icon">
          </div>
          <div @click="toggleAudioMode" v-if="!$store.state.playAudio">
            <img src="../assets/ear-slash.png" class="sol-tool-icon">
          </div>
        </div>
        <div class="w3-col s4 w3-center">
          <div @click="toggleImageMode" v-if="$store.state.showImage">
            <img src="../assets/eye.png" class="sol-tool-icon">
          </div>
          <div @click="toggleImageMode" v-if="!$store.state.showImage">
            <img src="../assets/eye-slash.png" class="sol-tool-icon">
          </div>
        </div>
        <div class="w3-col s4 w3-center">
          <div @click="toggleTextMode" v-if="$store.state.writeText">
            <img src="../assets/pencil.png" class="sol-tool-icon">
          </div>
          <div @click="toggleTextMode" v-if="!$store.state.writeText">
            <img src="../assets/abc.png" class="sol-tool-icon">
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="image-flexrow sol-border-side-bottom">
    <span v-touch:tap="playAudio" v-touch:swipe="swipeHandler">
      <img class="sol-word-image" :src="imageUrl">
    </span>
    <div @click="changeWord(-1)" class="w3-display-left sol-pointer">
      <img src="../assets/prev-arrow.png">
    </div>
    <div @click="changeWord(1)" class="w3-display-right sol-pointer">
      <img src="../assets/next-arrow.png">
    </div>
  </div>
  <div class="text-flexrow">
    <input type="text" @input="wordText = $event.target.value" :value="wordText"
      :disabled="!$store.state.writeText" class="sol-word-text sol-border-side-bottom" 
      autocomplete="off" placeholder="skriv her" name="word-text">
    <div v-if="$store.state.writeText" class="sol-border-side-bottom w3-light-grey">
      <div class="sol-progress" :style="{ width: progress + '%' }"></div>
    </div>
  </div>
</div>

</template>

<script>

import { db, storage } from '../firebase';
export default {

  data() {
    return {
      words: [],
      wordIndex: 0,
      wordText: '',
      imageUrl: '',
      wordAudio: new Audio(),
      okPling: new Audio(require('../assets/pling.wav')),
      progress: 0.5
    }
  },

  watch: {
    wordText(writtenText) {
      if (this.$store.state.writeText && writtenText.length > 0) {
        const wordText = this.words[this.wordIndex].text.toLowerCase();
        writtenText = writtenText.toLowerCase();
        if (writtenText === wordText) {
          this.progress = 100;
          this.okPling.play();
        } 
        else if (writtenText.startsWith(wordText)) {
          this.progress = (wordText.length / writtenText.length) * 100; 
        }
        else {
          let ie = 0;
          let i = writtenText.length;
          for (; i > 0; i--) {
            let writtenTextSlice = writtenText.slice(0, i);
            if (wordText.startsWith(writtenTextSlice)) {
              this.progress = (writtenTextSlice.length / (wordText.length + ie)) * 100;  
              break;
            }
            ie++;
          }
        }
      }
      else {
        this.progress = 0.5;
      }
    }
  },

  created() {
    const categoryKey = this.$route.params.cat;
    let dbRefPath = 'words/' + categoryKey;
    if ('subcat' in this.$route.params) {
      const subCategoryKey = this.$route.params.subcat;
      dbRefPath += '/' + subCategoryKey;
    }
    db.ref(dbRefPath).once('value')
      .then(snapshot => {
        if (snapshot.exists()) {
          Object.values(snapshot.val()).forEach((word, index) => {
            this.words[index] = {text: word.text};
            storage.refFromURL(word.imageUrl).getDownloadURL()
              .then(imageUrl => {
                this.words[index].imageUrl = imageUrl;
              })
              .then(() => {
                return storage.refFromURL(word.audioUrl).getDownloadURL()
              })
              .then(audioUrl => {
                this.words[index].audioUrl = audioUrl;
              })
              .then(() => {
                if (index === 0) {
                  this.changeWord(0);
                }
              })
          })
        }
        if (!snapshot.exists() || !this.$store.state.showImage) {
          this.imageUrl = require('../assets/no-image.png');
        }
      })
  },

  methods: {

    toggleAudioMode() {
      this.$store.state.playAudio = !this.$store.state.playAudio;
    },

    toggleImageMode() {
      this.$store.state.showImage = !this.$store.state.showImage;
      if (this.words.length > 0) {
        if (this.$store.state.showImage) {
          this.imageUrl = this.words[this.wordIndex].imageUrl;
        }
        else {
          this.imageUrl = require('../assets/no-image.png');
        }        
      }
    },

    toggleTextMode() {
      this.$store.state.writeText = !this.$store.state.writeText;
      if (this.words.length > 0) {
        if (this.$store.state.writeText) {
          this.wordText = '';
        }
        else {
          this.wordText = this.words[this.wordIndex].text;
        }
      }
    },

    swipeHandler(dir) {
      if (dir === 'right') {
        this.changeWord(-1);
      }
      else if (dir === 'left') {
        this.changeWord(1);
      }
    },

    changeWord(i) {
      const numWords = this.words.length;
      if (numWords > 0) {
        this.wordIndex += i;
        if (this.wordIndex === numWords) {
          this.wordIndex = 0;
        }
        else if (this.wordIndex === -1) {
          this.wordIndex = numWords - 1;
        }
        this.wordAudio.src = this.words[this.wordIndex].audioUrl;
        if (this.$store.state.playAudio) {
          this.playAudio();
        }
        if (this.$store.state.showImage) {
          this.imageUrl = this.words[this.wordIndex].imageUrl;
        }
        if (this.$store.state.writeText) {
          this.wordText = '';
        }
        else {
          this.wordText = this.words[this.wordIndex].text;
        }
      }
    },

    playAudio() {
      this.wordAudio.play();
    }

  }

}

</script>

<style scoped>

.sol-content-center-wx {
  max-width: 800px;
  margin: 0 auto;
}

.sol-words-flexbox {
  display: flex;
  flex-flow: column;
}

.sol-words-flexbox .tools-flexrow {
  flex: 0 1 auto;
}

.sol-words-flexbox .image-flexrow {
  flex: 1 1 auto;
  position: relative;
}

.sol-words-flexbox .text-flexrow {
  flex: 0 1 auto;
}

.sol-word-image {
  object-fit: cover;
  height: 500px;
  width: 100%;
}

@media screen and (max-height: 770px) {

  .sol-content-center-wx {
    max-width: 600px;
  }

  .sol-word-image {
    height: 420px;
  }

}

@media screen and (max-height: 690px) {

  .sol-word-image {
    height: 330px;
  }

}

@media screen and (max-width: 500px) {

  .sol-words-flexbox {
    height: 100%;
  }

  .sol-word-image {
    height: 100%;
  }

}

.sol-tool-icon {
  height: 50px;
  cursor: pointer;
}

.sol-border-side-bottom {
  border-style: solid;
  border-color: #aaa;
  border-width: 0 1px 1px;
}

.sol-word-text {
  font-size: 32px;
  font-weight: 600;
  text-align: center;
  width: 100%;
  padding: 4px;
}

.sol-word-text:focus {
  outline: none;
}

.sol-word-text:disabled {
  color: black;
  background-color: white;
}

.sol-progress { 
  height: 24px;
  background-color: #4cAf50
}

</style>
