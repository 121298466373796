import Vue from 'vue';
import VueRouter from 'vue-router';
import Categories from '../views/Categories.vue';
import Subcategories from '../views/Subcategories.vue';
import Words from '../views/Words.vue';
import LogIn from '../views/LogIn.vue';
import NewCategory from '../views/NewCategory.vue';
import UpdateCategory from '../views/UpdateCategory.vue';
import NewWord from '../views/NewWord.vue';

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: Categories
  },
  {
    path: '/kategori/:cat',
    component: Subcategories
  },
  {
    path: '/ord/:cat',
    component: Words
  },
  {
    path: '/ord/:cat/:subcat',
    component: Words
  },
  {
    path: '/admin',
    component: LogIn
  },
  {
    path: '/ny-kategori',
    component: NewCategory
  },
  {
    path: '/oppdater-kategori',
    component: UpdateCategory
  },
  {
    path: '/nytt-ord/:cat',
    component: NewWord
  },
  {
    path: '/nytt-ord/:cat/:subcat',
    component: NewWord
  },
  {
    path: '*',
    redirect: '/'
  }
]

const router = new VueRouter({
  routes
})

export default router
