import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import VuePaginate from 'vue-paginate';
import Vue2TouchEvents from 'vue2-touch-events';

Vue.use(VuePaginate);
Vue.use(Vue2TouchEvents);

Vue.config.productionTip = false;

// Define admin routes
let adminRoutes = {};
adminRoutes['/ny-kategori'] = true;
adminRoutes['/oppdater-kategori'] = true;
adminRoutes['/nytt-ord'] = true;

// Global route guards
router.beforeEach((to, from, next) => {
  let toPath = '/' + to.path.split('/')[1];
  toPath = toPath.toLowerCase();
  if (!store.state.adminLoggedIn && (toPath in adminRoutes)) {
    next(false);
  }
  else {
    store.state.showAdminMenuDropdown = false;
    store.state.showNavigateBackArrow = (to.path !== '/') ? true : false;
    next();
  }
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
